import { lazy, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import LoaderComponenet from '../components/common/Loader';


import { PrivateRoute } from './PrivateRoute';
import { Provider } from 'react-redux';
import { useLocalStorage } from './useLocalStorage';



const SalesDashboard = lazy(() => import('../components/Sales/Dashboard'));

const ClaimsDashboard = lazy(() => import('../components/ClaimProcessing/Dashboard'));
const Claims = lazy(() => import('../components/ClaimProcessing/Claims'))
const ClaimDetails = lazy(() => import('../components/ClaimProcessing/Claims/Details'))
const PreAuths = lazy(() => import('../components/ClaimProcessing/PreAuths'))
const PreAuthDetails = lazy(() => import('../components/ClaimProcessing/PreAuths/Details'))

const FinanceDashboard = lazy(() => import('../components/Finance/Dashboard/'))
const Payments = lazy(() => import('../components/Finance/Payments/'))
const PaymentDetails = lazy(() => import('../components/Finance/Payments/Details'))
const Providers = lazy(() => import('../components/Finance/Providers/'))
const ProviderDetails = lazy(() => import('../components/Finance/Providers/Details'))
const ProviderReceipts = lazy(() => import('../components/Finance/Providers/Details/Receipts'))
const Premiums = lazy(() => import('../components/Finance/Premiums/'))

const UnderwritingDashboard = lazy(() => import('../components/Underwriting/Dashboard'))
const Policies = lazy(() => import('../components/Underwriting/Policies'))
const EmployerPolicy = lazy(() => import('../components/Underwriting/Policies/EmployerPolicy'))


const OnboardingDashboard = lazy(() => import('../components/Onboarding/Dashboard'));
const Employers = lazy(() => import('../components/Onboarding/Employers'));
const OnboardEmployer = lazy(() => import('../components/Onboarding/Employers/Onboard'));
const EmployerDetails = lazy(() => import('../components/Onboarding/Employers/Details'));
const EmployerUploadDocuments = lazy(() => import('../components/Onboarding/Employers/Details/components/UploadDocuments'));
const EmployerCreateSubscription = lazy(() => import('../components/Onboarding/Employers/Details/components/CreateSubscription'));
const EmployerUploadBenefits = lazy(() => import('../components/Onboarding/Employers/Details/components/UploadBenefits'));
const EmployerBenefits = lazy(() => import('../components/Onboarding/Employers/Details/components/Benefits'));
const EmployerAddMembers = lazy(() => import('../components/Onboarding/Employers/Details/components/AddMembers'));

const CustomerSupportDashboard = lazy(() => import('../components/CustomerSupport/'));
const CustomerSupportDashboardForward = lazy(() => import('../components/CustomerSupport/Forward'));
const MemberDetails = lazy(() => import('../components/CustomerSupport/MemberDetails'));
const VisitHistory = lazy(() => import('../components/CustomerSupport/VisitHistory'));
const OpenVisits = lazy(() => import('../components/CustomerSupport/OpenVisits'));
const VisitDetails = lazy(() => import('../components/CustomerSupport/VisitDetails'));

const ReportsDashboard = lazy(() => import('../components/Reports'));

const InsightsDashboard = lazy(() => import('../components/Insights'));

const Login = lazy(() => import('../components/Auth/Login'));
const Welcome = lazy(() => import('../components/Auth/Welcome'));
const RequestToken = lazy(() => import('../components/Auth/ResetPassword/RequestToken'));
const ResetPassword = lazy(() => import('../components/Auth/ResetPassword'));
const Users = lazy(() => import('../components/Auth/Users'));

const NotFound = lazy(() => import('../components/common/404'));

const RouteSetup = () => {




  const ClaimProcessing = <>
    <Route
      path="/"
      element={
        <PrivateRoute>
          <ClaimsDashboard />
        </PrivateRoute>
      }
    />
    <Route
      path="/claims"
      element={
        <PrivateRoute>
          <Claims />
        </PrivateRoute>
      }
    />
    <Route
      path="/claims/:source/:claim_id"
      element={
        <PrivateRoute>
          <ClaimDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/pre-auths"
      element={
        <PrivateRoute>
          <PreAuths />
        </PrivateRoute>
      }
    />
    <Route
      path="/pre-auths/:preauth_id"
      element={
        <PrivateRoute>
          <PreAuthDetails />
        </PrivateRoute>
      }
    />
  </>

  const CustomerSupport = <>
    <Route
      path="/"
      element={
        <PrivateRoute>
          <CustomerSupportDashboardForward />
        </PrivateRoute>
      }
    />
    <Route
      path="/customer-support"
      element={
        <PrivateRoute>
          <CustomerSupportDashboard />
        </PrivateRoute>
      }
    />
    <Route
      path="/customer-support/member"
      element={
        <PrivateRoute>
          <Navigate to='/customer-support' />
        </PrivateRoute>
      }
    />
    <Route
      path="/customer-support/visit-history"
      element={
        <PrivateRoute>
          <Navigate to='/customer-support' />
        </PrivateRoute>
      }
    />
    <Route
      path="/customer-support/visits"
      element={
        <PrivateRoute>
          <Navigate to='/customer-support' />
        </PrivateRoute>
      }
    />
    <Route
      path="/customer-support/member/:memberNumber"
      element={
        <PrivateRoute>
          <MemberDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/customer-support/visit-history/:memberNumber"
      element={
        <PrivateRoute>
          <VisitHistory />
        </PrivateRoute>
      }
    />
    <Route
      path="/customer-support/visits/:visitId"
      element={
        <PrivateRoute>
          <VisitDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/customer-support/visits/open"
      element={
        <PrivateRoute>
          <OpenVisits />
        </PrivateRoute>
      }
    />
    <Route
      path="/customer-support/visit-history/provider/:providerId"
      element={
        <PrivateRoute>
          <VisitHistory isProvider={true} />
        </PrivateRoute>
      }
    />
  </>

  const Reports = <>
    <Route
      path="/reports"
      element={
        <PrivateRoute>
          <ReportsDashboard />
        </PrivateRoute>
      }
    />

  </>

  const Insights = <>
    <Route
      path="/insights"
      element={
        <PrivateRoute>
          <InsightsDashboard />
        </PrivateRoute>
      }
    />

  </>

  const Finance = <>
    <Route
      path="/"
      element={
        <PrivateRoute>
          <FinanceDashboard />
        </PrivateRoute>
      }
    />
    <Route
      path="/payments"
      element={
        <PrivateRoute>
          <Payments />
        </PrivateRoute>
      }
    />
    <Route
      path="/payments/:claim_id"
      element={
        <PrivateRoute>
          <PaymentDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/providers"
      element={
        <PrivateRoute>
          <Providers />
        </PrivateRoute>
      }
    />
    <Route
      path="/providers/:provider_id"
      element={
        <PrivateRoute>
          <ProviderDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/providers/:provider_id/receipts"
      element={
        <PrivateRoute>
          <ProviderReceipts />
        </PrivateRoute>
      }
    />
    <Route
      path="/premiums"
      element={
        <PrivateRoute>
          <Premiums />
        </PrivateRoute>
      }
    />

  </>

  const Underwriting = <>
    <Route
      path="/policies"
      element={
        <PrivateRoute>
          <Policies />
        </PrivateRoute>
      }
    />
    <Route
      path="/policies/:employer_id"
      element={
        <PrivateRoute>
          <EmployerPolicy />
        </PrivateRoute>
      }
    />


  </>

  const Sales = <>
    <Route
      path="/"
      element={
        <PrivateRoute>
          <SalesDashboard />
        </PrivateRoute>
      }
    />


  </>

  const Onboarding = <>
    <Route
      path="/"
      element={
        <PrivateRoute>
          <OnboardingDashboard />
        </PrivateRoute>
      }
    />
    <Route
      path="/employers"
      element={
        <PrivateRoute>
          <Employers />
        </PrivateRoute>
      }
    />
    <Route
      path="/employers/:account_id"
      element={
        <PrivateRoute>
          <EmployerDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/employers/:account_id/upload-documents"
      element={
        <PrivateRoute>
          <EmployerUploadDocuments />
        </PrivateRoute>
      }
    />
    <Route
      path="/employers/:account_id/create-subscription"
      element={
        <PrivateRoute>
          <EmployerCreateSubscription />
        </PrivateRoute>
      }
    />
    <Route
      path="/employers/:account_id/upload-benefits"
      element={
        <PrivateRoute>
          <EmployerUploadBenefits />
        </PrivateRoute>
      }
    />
    <Route
      path="/employers/:account_id/add-members"
      element={
        <PrivateRoute>
          <EmployerAddMembers />
        </PrivateRoute>
      }
    />
    <Route
      path="/employers/:account_id/benefits"
      element={
        <PrivateRoute>
          <EmployerBenefits />
        </PrivateRoute>
      }
    />
    <Route
      path="/employers/onboard"
      element={
        <PrivateRoute>
          <OnboardEmployer />
        </PrivateRoute>
      }
    />


    <Route
      path="/claims"
      element={
        <PrivateRoute>
          <Claims />
        </PrivateRoute>
      }
    />
    <Route
      path="/claims/:source/:claim_id"
      element={
        <PrivateRoute>
          <ClaimDetails />
        </PrivateRoute>
      }
    />
  </>

  const [loading, setLoading] = useState(true)
  const user = localStorage.getItem('user')
  const navigate = useNavigate()
  useEffect(() => {
    if (localStorage.getItem('token') === null && (window.location.pathname !== '/welcome' && window.location.pathname !== '/reset-password')) {
      setLoading(false)
      navigate('/login')
    }
    if (user || window.location.pathname === '/welcome' || window.location.pathname === '/reset-password') setLoading(false)
  }, [user])

  return (loading ?
    <LoaderComponenet /> :
    <Suspense fallback={<LoaderComponenet />}>
      <Routes>
        {/* {Finance} */}
        {Onboarding}
        {JSON.parse(user)?.team_role?.filter(tr => tr.team === 'Claim').length > 0 && ClaimProcessing}
        {JSON.parse(user)?.team_role?.filter(tr => tr.team === 'Support').length > 0 && CustomerSupport}
        {(JSON.parse(user)?.team_role?.filter(tr => tr.team === 'Support').length > 0 || JSON.parse(user)?.team_role?.filter(tr => tr.team === 'Claim').length > 0) && Reports}
        {(JSON.parse(user)?.team_role?.filter(tr => tr.team === 'Support').length > 0 || JSON.parse(user)?.team_role?.filter(tr => tr.team === 'Claim').length > 0) && Insights}
        {/* {Sales} */}
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/reset-password" element={<RequestToken />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="*" element={<PrivateRoute><NotFound /></PrivateRoute>} />
      </Routes>
    </Suspense>

  );
};

export default RouteSetup;
