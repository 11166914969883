import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { baseURL } from '../../config';
import { openNotification } from '../../components/common/helper';

const initialState = {
    token: typeof window !== 'undefined' && localStorage.getItem('token'),
    isLoading: false,
    error: null,
    claims: [],
    property: {},
    claimDetails: { isLoading: false, claim: null },
    snapshot: {},
    claim_type: localStorage.getItem('claimType') || 'Hospital'
};



export const listClaims = createAsyncThunk('claims/List', async ({ size, page, source, status, sort }, thunkAPI) => {
    return await fetch(`${baseURL}provider/medical-claim?size=${size}&page=${page}${source ? `&source=${source}` : ''}${(status && (status !== 'All')) ? ('&status=' + status) : ''}&sort=createdTimestamp,${sort}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    })
        .then(async res => {
            return res.json().then(resJson => {
                if (!res.ok) throw new Error(resJson?.error)
                return resJson
            })
        })
        .catch(e => thunkAPI.rejectWithValue(e.message))
});


export const searchClaims = createAsyncThunk('claims/Search', async ({ query, size, page }, thunkAPI) => {
    return await fetch(`${baseURL}pas/claim/search?query=${query}&size=${size}&page=${page}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    })
        .then(async res => {
            return res.json().then(resJson => {
                if (!res.ok) throw new Error(resJson?.error)
                return resJson
            })
        })
        .catch(e => thunkAPI.rejectWithValue(e.message))
});

export const listPharmacyClaims = createAsyncThunk('claims/ListPharmacy', async ({ size, page }, thunkAPI) => {
    return await fetch(`${baseURL}pas/claim/pharmacy/list?size=${size}&page=${page}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    })
        .then(async res => {
            return res.json().then(resJson => {
                if (!res.ok) throw new Error(resJson?.error)
                return resJson
            })
        })
        .catch(e => thunkAPI.rejectWithValue(e.message))
});

export const getClaimDetails = createAsyncThunk('claims/getClaim', async ({ claim_id, source }, thunkAPI) => {
    return await fetch(`${baseURL}provider/medical-claim/${claim_id}/source/${source}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    })
        .then(async res => {
            return res.json().then(resJson => {
                if (!res.ok) throw new Error(resJson?.error)
                return resJson
            })
        })
        .catch(e => thunkAPI.rejectWithValue(e.message))
});

export const getClaimSnapshot = createAsyncThunk('claims/getSnapshot', async (args, thunkAPI) => {
    return await fetch(`${baseURL}provider/medical-claim/stats`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    })
        .then(async res => {
            return res.json().then(resJson => {
                if (!res.ok) throw new Error(resJson?.error)
                return resJson
            })
        })
        .catch(e => thunkAPI.rejectWithValue(e.message))
});


export const ClaimSlice = createSlice({
    name: 'claims',
    initialState: initialState,
    reducers: {
        logOut: (state, actions) => {
            state.isLoading = false;
            state.isAuthenticated = false;
            state.token = null;
            localStorage.clear();
        },
        clearErr: (state, actions) => {
            state.error = null;
        },
        setClaimType: (state, action) => {
            state.claim_type = action.payload
            localStorage.setItem('claimType', action.payload)
        }
    },
    extraReducers(builder) {
        builder.addCase(listClaims.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(listClaims.fulfilled, (state, action) => {
            state.claims = action.payload.result?.content
            state.claims.property = action.payload.result?.property
            state.isLoading = false;
        });
        builder.addCase(listClaims.rejected, (state, action) => {
            openNotification(action.payload, 'Error')
            state.isLoading = false;
        });
        builder.addCase(searchClaims.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(searchClaims.fulfilled, (state, action) => {
            state.claims = action.payload.result.content
            state.claims.property = action.payload.result.property
            state.isLoading = false;
        });
        builder.addCase(searchClaims.rejected, (state, action) => {
            openNotification(action.payload, 'Error')
            state.isLoading = false;
        });
        builder.addCase(listPharmacyClaims.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(listPharmacyClaims.fulfilled, (state, action) => {
            state.claims = action.payload.result.content
            state.claims.property = action.payload.result.property
            state.isLoading = false;
        });
        builder.addCase(listPharmacyClaims.rejected, (state, action) => {
            openNotification(action.payload, 'Error')
            state.isLoading = false;
        });
        builder.addCase(getClaimDetails.pending, (state) => {
            state.claimDetails.isLoading = true;
        });
        builder.addCase(getClaimDetails.fulfilled, (state, action) => {
            state.claimDetails.claim = action.payload.result
            state.claimDetails.isLoading = false;
        });
        builder.addCase(getClaimDetails.rejected, (state, action) => {
            openNotification(action.payload, 'Error')
            state.claimDetails.isLoading = false;
        });
        builder.addCase(getClaimSnapshot.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getClaimSnapshot.fulfilled, (state, action) => {
            state.claims = action.payload.result.content
            state.snapshot = action.payload.result
        });
        builder.addCase(getClaimSnapshot.rejected, (state, action) => {
            openNotification(action.payload, 'Error')
            state.isLoading = false;
        });
    },
});
export const { clearErr, setClaimType } = ClaimSlice.actions
export default ClaimSlice.reducer;
